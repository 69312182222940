import $ from 'cash-dom';
import {Loader} from '@googlemaps/js-api-loader';
import {mapStyle} from "@scripts/components/index/map-style.js";
import {MarkerClusterer} from "@googlemaps/markerclusterer";
import {marker} from "../../../../../../../wp-includes/js/codemirror/csslint.js";
import FlexSearch from "flexsearch";
import {debounce} from "@scripts/core.js";
import {DateTime} from "luxon";

export const indexMapServiceComponent = () => wp.api.loadPromise.done(async () => {
    const loader = new Loader({
      apiKey: 'AIzaSyAc4llZeDKxSq-uCAxkE-Lv3s4QdiVv4CM',
      version: 'weekly',
      region: 'SK',
      language: 'sk',
    });
    const {Map, InfoWindow} = await loader.importLibrary('maps');
    const {AdvancedMarkerElement, PinElement} = await loader.importLibrary('marker');

    const context = {
      fetchId: 0,
    };

    const icons = {
      'contract': 'pin-1.png',
      'partner': 'pin-2.png',
      'glass': 'pin-3.png',
      'hail': 'pin-4.png',
    };
    const classes = {
      'contract': 'border-l-2 border-red',
      'partner': 'border-l-2 border-blue',
      'glass': 'border-l-2 border-green-service',
      'hail': 'border-l-2 border-yellow-service',
    };
    const types = {
      'partner': 'Partnerský servis',
      'contract': 'Zmluvný servis',
      'glass': 'Oprava skla',
      'hail': 'Servis opráv po krupobití',
    };

    return Promise.all(
      $('.map-services').get().map(async (root) => {

        const $root = $(root);
        const $mapContainer = $root.find('.map-container');
        const $search = $root.find('.map-search-input');
        const $cards = $root.find('.map-card');
        const $cardTemplate = $root.find('#map-card-template');
        const $contractCards = $root.find('#contract-map-cards');
        const $partnerCards = $root.find('#partner-map-cards');
        let results = [];
        const markers = {};
        const markerResults = {};
        const cards$ = {};
        const position = {lat: 48.74837949102991, lng: 19.21017518456245};
        let zoom = 8;

        if ('geolocation' in navigator) {
          navigator.geolocation.getCurrentPosition((geoposition) => {
            position.lat = geoposition.coords.latitude
            position.lng = geoposition.coords.longitude;
            zoom = 9;
            console.log('geolocation success', position);
            init();
          }, () => {
            console.log('geolocation error');
            init();
          });
        } else {
          console.log('geolocation disabled');
          init();
        }

        const index = new FlexSearch.Index({
          charset: 'latin:simple',
          tokenize: 'forward',
        });

        let map/*, clusterer*/;

        const init = function () {
          console.log('init', position);
          map = new Map($mapContainer.get(0), {
            center: position,
            zoom,
            maxZoom: 15,
            disableDefaultUI: true,
            styles: mapStyle,
            mapId: '7930465941fdbb2d',
          });

          /*
                    const legend = $root.find('#map-legend-template').get(0);
                    legend.remove();
                    map.controls[google.maps.ControlPosition.TOP_LEFT].push(legend);
                    console.log(legend);
          */

          /*
                    clusterer = new MarkerClusterer({
                      map,
                      markers: [],
                    });
          */

          const distance = (a) => Math.pow(a.acf['latitude'] - position.lat, 2) + Math.pow(a.acf['longitude'] - position.lng, 2);

          const createCard = (result) => {
            const cardType = result.acf['type'];

            const $card = $cardTemplate.clone();
            $card.attr('id', `map-card-${result.id}`);
            $card.attr('data-type', cardType);
            $card.find('.map-card').addClass(classes[cardType]);
            $card.find('.map-card-title').html(result.title.rendered);
            $card.find('.map-card-icon').attr('src', `/wp-content/themes/wushomepage/resources/images/services/${icons[cardType]}`);
            $card.find('.map-card-address').html(result.acf['address'] + "<br>" + result.acf['zip_code'] + ' ' + result.acf['city']);
            $card.find('.map-card-link').attr('href', result.link);
            $card.find('.phone-link-mobile').attr('href', `tel:${result.acf.phone}`);
            $card.find('.phone-link-mobile-label').text(result.acf.phone);
            $card.find('.phone-link').each((n, el) => {
              const $el = $(el);
              const value = result.acf[n ? 'phone_2' : 'phone'];
              $el.attr('href', `tel:${value}`);
              $el.text(value);
            });

            const openingHourKeys = [
              '',
              'opening_hours_monday',
              'opening_hours_tuesday',
              'opening_hours_wednesday',
              'opening_hours_thursday',
              'opening_hours_friday',
              'opening_hours_saturday',
              'opening_hours_sunday',
            ];
            const openingHours = result.acf[openingHourKeys[DateTime.now().weekday]];

            $card.find('.map-card-opening-hours').text(openingHours || '');

            const times = openingHours.split('-');
            if (times.length >= 2) {
              const [start, end] = times;
              const isAfterStart = DateTime.fromFormat(start.trim(), 'H:m').diffNow().as('seconds') < 0;
              const isBeforeEnd = DateTime.fromFormat(end.trim(), 'H:m').diffNow().as('seconds') > 0;

              $card.find('.map-card-opening-hours-light').css('color', isAfterStart && isBeforeEnd ? '#419F5A' : '#E61414');
            }

            cards$[result.id] = $card;
            return $card;
          };

          const fetchBranches = async (onUpdate, search = '') => {
            let fetchId = ++context.fetchId;

            const collection = new wp.api.collections.Servisy();
            const result = await collection.fetch({
              data: {
                per_page: 100,
              }
            });

            while (collection.hasMore()) {
              result.push(...await collection.more());

              if (fetchId !== context.fetchId)
                return undefined;
            }

            onUpdate(result, false, false);

            return result;
          };

          const onUpdate = (newResults, hasMore, isFirst) => {
            /*
                      if (isFirst)
                        clusterer.clearMarkers();
            */

            results = [...newResults];
            results.sort((a, b) => distance(a) - distance(b))
              .forEach((result, n) => {
                if (markers[result.id] || !result.acf['latitude'] || !result.acf['longitude'])
                  return;

                const pin = document.createElement('img');
                pin.src = `/wp-content/themes/wushomepage/resources/images/services/${icons[result.acf['type']]}`;

                pin.style.height = result.acf['type'] === 'contract' ? '43px' : '40px';
                /*
                            const pin = new PinElement({
                              background: result.acf['is_partner'] ? '#E61414' : '#F3F5F7',
                              borderColor: result.acf['is_partner'] ? '#AF1414' : '#D6DDE6',
                              glyphColor: result.acf['is_partner'] ? '#821414' : '#61666C',
                            });
                */
                const marker = new AdvancedMarkerElement({
                  position: {
                    lat: result.acf['latitude'],
                    lng: result.acf['longitude'],
                  },
                  content: pin,
                  // content: pin.element,
                  map,
                });

                const infowindow = new InfoWindow({
                  content: `<strong>${result.title.rendered}</strong><br>
                    ${result.acf['address']}, ${result.acf['city']}<br>
                    ${types[result.acf['type']]}<br>
                    <a class="underline hover:no-underline text-red-dark font-medium" target="_blank" href="${result.link}">Detail servisu</a>`
                });
                marker.addListener('click', () => {
                  infowindow.open(map, marker);
                });
                markers[result.id] = marker;
                markerResults[result.id] = result;
                index.add(result.id, `${result.acf['city']} ${result.title.rendered} ${result.acf['zip_code']} ${result.acf['address']}`);

                // clusterer.addMarker(marker);

                if (result.acf['type'] === 'contract') {
                  $contractCards.append(createCard(result));
                } else {
                  $partnerCards.append(createCard(result));
                }
              });

            if (!hasMore)
              onSearch('');
          };

          const onSearch = (value) => {
            const bounds = new google.maps.LatLngBounds();

            let searchResults = value.length >= 3 ? index.search(value) : [];
            if (value.length >= 3 && searchResults.length) {
              // clusterer.clearMarkers();
              Object.values(markers).forEach(marker => marker.map = null);
              Object.values(cards$).forEach($card => $card.addClass('hidden'));

              if (searchResults.length >= 1 && searchResults.length < 4) {
                const origin = markerResults[searchResults[0]];
                console.log(searchResults);
                const distance = (a, b) => {
                  // console.log(a, b);
                  if (!a.acf['latitude'] || !a.acf['longitude'])
                    return Infinity;
                  if (!b.acf['latitude'] || !b.acf['longitude'])
                    return Infinity;

                  const lat = b.acf['latitude'] - a.acf['latitude'];
                  const long = b.acf['longitude'] - a.acf['longitude'];
                  return lat * lat + long * long;
                };

                const closestResults = [...results].sort((a, b) => distance(origin, a) - distance(origin, b));
                searchResults = closestResults.slice(0, 4).map(result => result.id);
              }

              searchResults.forEach((id, n) => {
                const result = markerResults[id];
                const marker = markers[id];

                // clusterer.addMarker(marker);
                bounds.extend(marker.position);
                marker.map = map;

                cards$[result.id].removeClass('hidden');
              });

              map.fitBounds(bounds);
            } else {
              // clusterer.clearMarkers();
              Object.values(markers).forEach(marker => marker.map = map);
              const counts = {
                partner: 0,
                contract: 0,
              };
              Object.values(cards$).forEach(($card, index) => {
                const cardType = $card.attr('data-type');
                const count = ++counts[cardType === 'contract' ? 'contract' : 'partner'];

                if (count < 21)
                  $card.removeClass('hidden');
                else
                  $card.addClass('hidden');
              });

              results.forEach((result, n) => {
                const marker = markers[result.id];

                if (!marker || !result.acf['latitude'] || !result.acf['longitude'])
                  return;

                // clusterer.addMarker(marker);
                bounds.extend(marker.position);
              });

              map.setCenter(position);
              map.setZoom(zoom)
            }
          };

          $search.on('input', debounce(500, e => onSearch(e.target.value)));

          fetchBranches(onUpdate, $search.val());
        }
      })
    );
  }
);
