import $ from 'cash-dom';
import axios from 'axios';

export const productSignUpPopup = async () => {
  const $popupButton = $('.sign-up-button');
  const $popup = $('#sign-up-popup');
  const $form = $popup.find('#sign-up-form');

  console.log($popupButton.get(), $popup.get());

  $popupButton.on('click', () => {
    $popup.removeClass('hidden');
    $popup.addClass('flex');
  });

  $form.on('submit', async () => {
    /** @type HTMLFormElement */
    const form = $form.get(0);

    const data = {
      'firstName': $form.find('#first-name').val(),
      'lastName': $form.find('#last-name').val(),
      'postalCode': $form.find('#postal-code').val(),
      'phone': $form.find('#phone').val(),
      'email': $form.find('#email').val(),
      'agreeMarketing': $form.find('#agree-marketing').is(':checked'),
      'agreeContest': $form.find('#agree-contest').is(':checked'),
      'agreeGdpr': $form.find('#agree-gdpr').is(':checked'),
    };

    try {
      await axios.post('', data);
    } catch (err) {
      console.error(err);
    }

    return false;
  });
};
